/* CalendarPage.css */
.fc-toolbar {
  background-color: #303E4A;
  color: #ffffff;
  padding: 10px;
}

.fc-button {
  background-color: #1EB980;
  border: none;
  color: #ffffff;
}

.fc-button:hover {
  background-color: #17A472;
}

.fc-button-primary:not(:disabled).fc-button-active {
  background-color: #17A472;
  border: none;
}

.fc-daygrid-event {
  background-color: #1EB980;
  border: none;
  color: #ffffff;
  border-radius: 5px;
}

.fc-event-main-frame {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-daygrid-block-event .fc-event-time {
  display: none;
}

.fc-daygrid-block-event .fc-event-title {
  font-size: 14px;
}

.fc-theme-bootstrap .fc-event {
  font-size: 14px;
  border-radius: 5px;
}

/* CalendarPage.css */
.fc-daygrid-day-number {
  color: #ffffff; /* Color of the date */
}

.fc-day-today {
  background-color: #1EB980; /* Background color of the current day */
}

.fc-col-header-cell {
  color: #ffffff; /* Color of the day names (Mon, Tue, Wed, etc.) */
}